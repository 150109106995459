<template>
  <div>
    <h1 class="h3">
      {{ `${$t('customer.customer_category')}` }}
    </h1>
    <div class="row mt-4">
      <div class="col-md-5">
        <a-input-search
          v-model="keyword"
          size="large"
          :placeholder="$t('store_list_page.search_products')"
          :loading="isLoading"
          :disabled="isLoading"
          allow-clear
          @search="onSearch"
        >
          <a-button slot="enterButton" type="danger" icon="search">
            {{ $t('utils.search') }}
          </a-button>
        </a-input-search>
      </div>
      <div class="col-md-3 p-0" />
    </div>
    <div class="mt-3">
      <a-table
        class="product-list mt-3 mb-2 bg-white"
        :data-source="content"
        :row-key="(record) => record.id"
        :columns="columns"
        :loading="isLoading"
        :pagination="false"
      >
        <template slot="category_name" slot-scope="text">
          <b style="color: #2196F3">
            {{ text || '-' }}
          </b>
        </template>
      </a-table>
    </div>
    <div class="mt-3 text-right">
      <a-pagination
        v-model="params.page"
        :page-size="params.limit"
        :page-size-options="sizeOptions"
        :total="total"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        :disabled="isLoading"
        show-size-changer
        @change="onPageChange"
        @showSizeChange="onShowSizeChange"
      />
    </div>
  </div>
</template>

<script>
import { getCustomerCategory } from '@/api/channels/distributor'
import { customerCategoryColumn } from '@/data/columns'
import { rulesAddPriceArea } from '@/data/rules'

export default {
  name: 'PriceArea',
  data() {
    return {
      keyword: '',
      screenHeight: ((screen.height - 900) * 0.45) + (screen.height * 0.45),
      rules: rulesAddPriceArea,
      sorting: 'Nama',
      activeKey: 'all',
      activeStatus: 'all',
      showProductModal: false,
      showUploadModal: false,
      columns: customerCategoryColumn,
      // filteredColumns: [],
      selectedProducts: [],
      sorts: ['Nama'],
      // tabs: ['all', 'unconfirmed', 'confirmed', 'notActive'],
      statusProduct: ['all', 'active', 'notActive'],
      sizeOptions: ['10', '20', '30', '40', '50'],
      detailProduct: {},
      params: {
        page: 1,
        limit: 10,
      },
      loadingSearch: false,
      loadingMasterModal: false,
      controllerList: null,
      controllerOrderCatalog: null,
      eventProcess: false,
      requestId: '',
      isLoadingSync: false,
      isLoading: false,
      onShow: false,
      rowData: {},
      content: [],
      total: 0,
      cityAll: false,
      districtAll: false,
      districtList: [],
      cityList: [],
      edit: false,
    }
  },
  computed: {
    products() {
      return this.$store.state?.productChannel?.products || []
    },
    props() {
      return this.$store.state?.productChannel?.props || {}
    },
    scroll() {
      return { y: this.screenHeight > 400 ? this.screenHeight : 400 }
    },
    tHeight() {
      return !this.products.length
        ? '0px'
        : this.screenHeight > 400
        ? `${this.screenHeight}px`
        : '400px'
    },
    tabs() {
      if (
        this.$route.query &&
        this.$route.query.workspace_id.includes('KINO')
      ) {
        return ['all', 'active', 'notActive']
      }
      return ['all', 'unconfirmed', 'confirmed']
    },
    httpHeader() {
      return {
        'Business-Id': this.businessId,
      }
    },
  },
  watch: {
    '$route.query'() {
      this.init()
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    // cancel the request
    if (this.controllerList) {
      this.controllerList.abort()
      this.controllerList = null
    }
    if (this.controllerOrderCatalog) {
      this.controllerOrderCatalog.abort()
      this.controllerOrderCatalog = null
    }
  },
  methods: {
    onSplit(e) {
      const map = e.map(v => {return v.name})
      const firstFive = map.slice(0, 10);
      const temp = {
        init: firstFive.toString(),
        count: e.length > 10 ? e.length - 10 : 0,
      }
      return temp
    },
    onSearch(val) {
      this.loadingSearch = true
      this.params.page = 1
      this.fetchCustomerCategory( val.trim() || undefined ) // this.keyword
    },
    init() {
      this.params = {
        page: 1,
        limit: 10,
      }
      this.fetchCustomerCategory()
    },
    async fetchCustomerCategory(search = '') {
      this.isLoading = true
      this.content = []
      await getCustomerCategory({
        business_id: this.$route.query.business_id,
        params: {
          business_id: this.$route.query.business_id,
          search,
          page: this.params.page,
          limit: this.params.limit,
          order: 'createdAt',
        },
      })
      .then(({ data }) => {
        this.content = data?.content ?? []
        this.total = data?.totalElements
        this.isLoading = false
      })
      .catch(err => {
        console.error(err)
        this.isLoading = false
      })
    },
    onPageChange(pageNumber) {
      this.params.page = pageNumber
      this.fetchCustomerCategory( this.keyword.trim() || undefined )
    },
    onShowSizeChange(current, pageSize) {
      this.selectedProducts = []
      this.params.page = current
      this.params.limit = pageSize
      this.fetchCustomerCategory()
    },
  },
}
</script>

<style lang="scss">
.product-list div.ant-table-body {
  min-height: v-bind(tHeight);
}
.ant-table-row {
  background-color: #fff !important;

  &.ant-table-row-selected > td {
    background-color: #fff !important;
  }
}
.pca-tab-store {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-nav .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff0a54;
  }
  .ant-tabs-ink-bar {
    background-color: #ff0a54;
  }
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
.pca-tab-status {
  .ant-tabs-bar {
    // background-color: #fff;
    margin-bottom: 0;
    border-bottom-color: transparent;
  }
  .ant-tabs-tab {
    padding: 0 25px !important;
    border-radius: 20px !important;
  }
  .ant-tabs-tab-active {
    background: #ff0a54 !important;
    color: #fff !important;
  }
}
.inbound__button {
  color: #ff0854 !important;
  background: #fff !important;
  border-color: #ff0854 !important;
}
.content {
  width: 100%;
  padding: 1rem;
  // border: 1px solid #999;
  border-radius: 5px;
  border-top-left-radius: 0;

  .search-box {
  width: 228px;
  }
  .list {
  padding: 1rem;
  // border: 1px solid #999;
  border-radius: 5px;
  }
  .header-list {
    font-weight: 900;
    background: #E7ECEF;
    border-radius: 5px ;
    padding: 10px;
  }
  .data-list {
    padding: 5px 0;
    margin: 10px 0;
    background: #fff;
    border-radius: 5px ;
  }

  // ANT DESIGN
  .ant-collapse {
    border: none
  }
}
.pca_modalCustomer .ant-form-item-label {
  line-height: 20px !important;
}
</style>
